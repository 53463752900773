<!--订单列表-->
<template>
  <div class="account-model">
    <div class="global-header">
      <el-form inline :model="filterInfo">
        <el-form-item label="借款订单编号">
          <el-input
              v-model="filterInfo.loanOrderNo"
              placeholder="借款订单编号"
              clearable
          />
        </el-form-item>
        <el-form-item label="客户号">
          <el-input
              v-model="filterInfo.customerNo"
              placeholder="客户号"
              clearable
          />
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input
              v-model="filterInfo.userId"
              placeholder="用户ID"
              clearable
          />
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input
              v-model="filterInfo.userName"
              placeholder="用户昵称"
              clearable
          />
        </el-form-item>
        <el-form-item label="用信流水号">
          <el-input
              v-model="filterInfo.disburseSerialNo"
              placeholder="用信流水号"
              clearable
          />
        </el-form-item>
        <el-form-item label="">
          <el-button
              :loading="tableLoad"
              type="primary"
              icon="el-icon-search"
              @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
          >搜 索
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="global-view-table">
      <div class="table-header" style="justify-content: space-between">
        <el-radio-group v-model="filterInfo.state" @change="changeTab">
          <el-radio-button label="null">全部</el-radio-button>
          <el-radio-button :label="item.value" v-for="(item,i) in stateList" :key="i">{{ item.label }}
          </el-radio-button>

        </el-radio-group>

        <div>
          <el-button size="mini" type="primary" @click="toRepay">手动还款入口</el-button>
          <el-button size="mini" type="primary" @click="toFilter">筛选最近订单</el-button>
        </div>
      </div>

      <el-table v-loading="tableLoad" border :data="tableData">
        <el-table-column prop="loanId" label="订单ID" width="70" fixed="left"/>
        <el-table-column prop="loanOrderNo" label="借款订单号" width="200" fixed="left"/>
        <el-table-column prop="disburseSerialNo" label="借款流水" width="200"/>
        <el-table-column prop="userId" label="用户ID" width="80">
          <template slot-scope="{row}">
            <span style="cursor: pointer;color: #409EFF;" @click="toUser(row.userId)">{{ row.userId }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="customerNo" label="客户号" width="240"/>
        <el-table-column prop="userName" label="姓名" width="80"/>
        <el-table-column prop="amount" label="借款金额" width="120"/>
        <el-table-column prop="periods" label="借款期数" width="120">
          <template slot-scope="{row}">
            <span v-if="row.periods">{{ row.periods }}月</span>
          </template>
        </el-table-column>
        <el-table-column prop="borrowTime" label="借款时间" width="145"/>
        <el-table-column prop="realRemitTime" label="实际打款时间" width="145"/>
        <el-table-column prop="repaymentPlanTime" label="借款计划结清时间" width="145"/>
        <el-table-column prop="state" label="状态" width="140">
          <template slot-scope="{row}">
            {{ findState(row.state).label }}
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" fixed="right" width="160">
          <template slot-scope="{ row }">
            <el-button size="small" @click="toDetail(row)">查看</el-button>
            <el-dropdown v-if="row.state == -1" style="margin-left: 7px;"
                         @command="handleCommand($event,row)">
              <el-button type="primary" size="mini">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="repay">重新打款</el-dropdown-item>
                <el-dropdown-item command="cancel" style="color: red;">取消订单
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <GPagination
          :total="total"
          :current-page="pageInfo.pageNum"
          :size="pageInfo.pageSize"
          @change="changePage"
      />
    </div>


    <el-dialog title="失败原因" :visible.sync="dialogFormVisible" center="">
      <div style="max-height: 500px;overflow-y: auto;">
        <!--<el-card style="margin-bottom: 10px;" shadow="never">-->
        <el-descriptions class="margin-top" :column="1">
          <!--<el-descriptions-item label="打款编号">kooriookami</el-descriptions-item>-->
          <!--<el-descriptions-item label="打款时间">18100000000</el-descriptions-item>-->
          <el-descriptions-item label="失败原因">{{ failReason }}</el-descriptions-item>
        </el-descriptions>
        <!--</el-card>-->
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrderPage, repayOrder, cancelOrder
} from "@/api/business";
import {filterFormat} from "@/utils/tools";
import {DatePicker} from "element-ui";

import GPagination from "@/components/GPagination";

export default {
  name: "account-list",
  components: {
    GPagination,
    DatePicker
  },
  data() {
    return {
      channelSource: [],
      filterInfo: {
        customerNo: "",
        disburseSerialNo: "",
        loanOrderNo: "",
        state: null,
        userId: null,
        userName: ""
      },

      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      dialogFormVisible: false,
      failReason: null,
      stateList: [{
        label: '订单作废',
        value: '-2'
      }, {
        label: '打款失败',
        value: '-1'
      }, {
        label: '初始',
        value: '0'
      }, {
        label: '签名中',
        value: '1'
      }, {
        label: '等待打款',
        value: '2'
      }, {
        label: '打款中',
        value: '3'
      }, {
        label: '打款成功等待还款',
        value: '4'
      }, {
        label: '结清',
        value: '5'
      }]
    };
  },
  created() {
    this.search(this.pageInfo);
  },
  methods: {
    toRepay() {
      this.$router.push({path: '/repayAction'})
    },

    toFilter() {
      this.$router.push({path: '/filterOrder'})
    },

    handleCommand(command, row) {
      console.log('click on item ', command, row)
      switch (command) {
        case 'repay':
          this.repayOrder(row)
          break;
        case 'cancel':
          this.cancelOrder(row)
          break
      }
    },
    repayOrder(row) {
      this.$messageBox
          .confirm("是否确认重新打款？", "确认重新打款", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "error",
          })
          .then(() => {
            repayOrder({
              loanOrderNo: row.loanOrderNo,
            }).then(() => {
              this.$message.success("重新打款成功");
              this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },

    cancelOrder(row) {
      this.$messageBox
          .confirm("是否确认取消该笔订单？", "确认取消", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "error",
          })
          .then(() => {
            cancelOrder({
              loanOrderNo: row.loanOrderNo,
            }).then(() => {
              this.$message.success("取消成功");
              this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },


    findState(val) {
      return this.stateList.find(item => item.value == val)
    },

    toUser(id) {
      this.$router.push({path: '/userDetail', query: {id: id}})
    },
    changeTab(val) {
      this.filterInfo.state = val;
      this.search({pageNum: 1, pageSize: this.pageInfo.pageSize})
    },
    changePage(page) {
      this.search(page);
    },
    toDetail(row) {
      this.$router.push({path: '/orderDetail', query: {id: row.id, loanOrderNo: row.loanOrderNo}})
    },

    changeTime(time) {
      if (time && time.length) {
        this.filterInfo.startDate = `${time[0]} 00:00`;
        this.filterInfo.endDate = `${time[1]} 23:59`;
      } else {
        this.filterInfo.startDate = "";
        this.filterInfo.endDate = "";
      }
    },

    search(page) {
      this.pageInfo = page;
      this.tableLoad = true;
      getOrderPage({
        ...this.pageInfo,
        ...filterFormat(this.filterInfo),
      })
          .then((res) => {
            const {total, records, pageNum} = res.data;
            this.tableData = records;
            this.total = total;
            this.pageInfo.pageNum = pageNum;
            this.tableLoad = false;
          })
          .catch(() => {
            this.tableLoad = false;
          });
    }
  },
};
</script>

<style lang="scss">
.account-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;

  .global-view-table {
    height: auto;
    margin-top: 16px;
    padding: 16px;
  }

  .role-tag {
    margin-right: 8px;
  }
}

.account-dialog {
  .el-dialog__body {
    padding: 24px 24px 0;
  }
}
</style>
